export default {
  appDescription:
    "It is very helpful for calculation of all profit, just input some neccessary info.",
  calculate: "Calculate",
  menu: {
    calculate: "Calculate",
    user: "User",
    agent: "Agent",
  },
  user: {
    playAmount: "Play Amount",
    oddRate: "Odd rate amount",
    bonusRate: "Bonus Rate",
    profitBeforeBonus: "Profit before bonus",
    profitAfterBonus: "Profit after bonus",
    bonusAmount: "Bonus Amount",
    result: "Result",

    subTab: {
      single: "Single",
      multi: "Multi",
      generateBetString: "Generate Bet String"
    },
    userMulti: {
      userProfit: "User Profit",
      inputFormat: "Input Format",
      totalPlayAmount: "Total Play Amount",
      totalBonus: "Total Bonus",
      totalProfit: "Total Profit",
      remindText : `输入框支持多行数据，每一行的格式如下 ：项目名 | 球号 | 原始投注金额 | 赔率 | 退水率 | 输或赢`,
    },
    generateBetString: {
      ball: "Ball",
      amount: "Amount",
      space: "Space",
      comma: "Comma",
      semicolon: "Semicolon",
      dropList: "Delimiter",
      copy: "Copy",
      paste: "Paste",
      result2 : "{balls} total {totalBall} balls, every ball amount {amount}."
    }
  },
  agent: {
    subTab: {
      onlyShareholder: "Only Shareholder",
      onlyBonusDiff: "Only Bonus Diff",
      onlyOddDiff: "Only Odd Diff",
      onlyHedge: "Only Hedge",
      complexCondition: "Complex Condition",
      temaPredictProfit: "Tema Predict Profit",
      bonusOddDiff: "Bonus&Odd Diff",
      userProfit: "User Profit",
      agentProfit: "Agent Forward Part",
      agentOwnPartProfit: "Agent Own Part Profit"
    },
    tema: {
      hitBallCollectAmount: "Hit ball collect amount",
      otherBallCollectAmounts: "Other ball collect amounts",
      oddRateAmountToday: "Odd rate amount today",
      bonusRateToday: "Bonus rate today",

      resultPredict: "Predict",
    },
    overceed: {
      tema: "Tema",
      zhengMa: "ZhengMa",
      zhengMaTe: "ZhengMaTe",
      twoSides: "2Sides",
      colors: "Colors",
      teMaAnimal: "TeMaAnimal",
      dropList: "Drop list",
      bonusRate: "Bonus rate",
      oddRate: "Odd rate",
      result: "Result",
      overceed: "Overceed",
      totalBall: "Total ball",
    },
    shareholder: {
      originalProfitAmount: "Original profit amount",
      originalBonusAmount: "Original bonus amount",
      parentShareholder: "Parent shareholder",
      currentShareholder: "Current shareholder",

      resultOccupiedProfitAmount: "Profit amount I occupied",
      resultOccupiedBonusAmount: "Profit amount I occupied after Bonus",
      resultContributeProfitAmountToParent:
        "Profit amount I contribute to parent",
      resultContributeRealProfitAmountToParent:
        "Real profit amount I contribute to parent",
    },
    bonusAndOddDiff: {
      bonusAmountPayToBelow: "Bonus amount pay to below",
      bonusAmountParentPayToMe: "Bonus amount parent pay to me",
      bonusCommissionAmount: "Bonus commission amount",
      OddAmountPayToBelow: "Odd amount pay to below",
      oddAmountParentPayToMe: "Odd amount parent pay to me",
      oddCommissionAmountTotalProfit: "Odd commission amount",
      totalProfit: "Total Profit",
    },
    bonusDiff: {
      originalAmount: "Original amount",
      parentBonusRate: "Parent bonus rate",
      currentBonus: "Current bonus rate",
      parentShareholderRate: "Parent's shareholder rate",

      resultOccuppiedBonusAmount: "How much bonus amount I occupied",
      resultOccuppiedCommissionAmount: "How much commission amount I occupied",
      resultBonusAmountParentIdGiveToMe:
        "How much Bonus amount parent id give to me",
      reminderInfo: "(本级赚水：只能赚取本级贡献给上级的金额的退水差率）",
    },
    oddDiff: {
      originalAmount: "Original amount",
      parentOddRate: "Parent odd rate",
      currentOddRate: "Current odd rate",
      parentShareholder: "Parent Shareholder",

      resultMakeMoneyFromOddDiff: "how much I make money from odd diff",
    },
    hedge: {
      hedgeAmount: "Hedge amount",
      parentOddRate: "Parent odd rate amount",
      bonus: "Bonus",
    },
    userProfit: {
      userProfit: "User Profit",
      inputFormat: "Input Format",
      totalPlayAmount: "Total Play Amount",
      totalBonus: "Total Bonus",
      totalProfit: "Total Profit",
      remindText : "输入框支持多行数据，每一行的格式如下 ：项目名 | 球号 | 原始投注金额 | 赔率 | 退水率 | 输或赢"
    },
    agentProfit: {
      agentProfit: "Agent Profit",
      inputFormat: "Input Format",
      totalForwardAmount: "Total Forward Admount",
      totalBonusParentPayToMe: "Total Bonus Parent Pay To Me",
      totalCommissionBonus: "Total Commission Bonus",
      totalCommissionOdd: "Total Commission Odd",
      totalProfitParentPayToMe: "Total Profit Parent Pay To Me",
      remindText : "输入框支持多行数据，每一行的格式如下 ：项目名 | 球号 | 原始投注金额 | 赔率 | 退水率 | 输或赢"
    },
  },
  calculateResult: "Calculation Result",
  moreFeatures: "More Features",
  formulas: "Formulas",
  winlost: "Win/Lost",
  win: "Win",
  lost: "Lost",
  clear: "Clear",
};
