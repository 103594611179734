import Calculator from "./../calculator.vue";

export default {
  name: "home",
  data() {
    return {
      type: "",
      tabActive: "calculator", //enums: calculator, user, agent
      subUserTabActive: "single", //enums: single, multi,
      subAgentTabActive: "tema", //enums: tema, overceed, shareholder, bonusdiff, odddiff, hedge, complex, bonusAndOddDiff
      tooltipActive: "",
      user: {
        odd: 0, // A
        amount: 0, // B
        rate: 0, // C
        status: 1, // D
      },
      agentOwnPartProfit: {
        inputFormat: `TeMa|09|100|80|3.2|46.5|2300`
      },
      userMulti:{
        error: [],
        inputFormat: `TeMa|09|100|47|3.2|Win
TeMa|33|200|47|3.2|Lose
3hits3|01,02,03|10|776|12.9|Lose`
      },
      generateBetString:{
        dropList: [
          { label: `${this.$t('user.generateBetString.space')} ( )`, val: " "},
          { label: `${this.$t('user.generateBetString.comma')} (,)`, val: ","},
          { label: `${this.$t('user.generateBetString.semicolon')} (;)`, val: ";"}
        ],
        balls: "1.2.3.4.5.6.7.8.9.10",
        amount: 0,
        splitCodeSelected: " "
      },
      agent: {
        originalProfit: 0, // A
        originalAmount: 0, // B
        belowLayerBonusAmount: 0, // C
        agentShareholdRate: 0, // D percentage
        parentShareholdRate: 0, // E percentage
        bonusRate: 0, // H
      },
      tema: {
        hitBallCollectAmount: 0, // A
        otherBallCollectAmounts: 0, // B
        oddRateAmountToday: 0, // C
        bonusRateToday: 0, // D
      },
      overceed: {
        dropList: [
          { label: `${this.$t('agent.overceed.tema')} (${this.$t('agent.overceed.totalBall')} : 49)`, val: 49},
          { label: `${this.$t('agent.overceed.zhengMa')} (${this.$t('agent.overceed.totalBall')} :  8.1667)`, val: 8.1667},
          { label: `${this.$t('agent.overceed.zhengMaTe')} (${this.$t('agent.overceed.totalBall')} : 49)`, val: 49},
          { label: `${this.$t('agent.overceed.twoSides')} (${this.$t('agent.overceed.totalBall')} : 2)`, val: 2},
          { label: `${this.$t('agent.overceed.colors')} (${this.$t('agent.overceed.totalBall')} : 3)`, val: 3},
          // { label: `${this.$t('agent.overceed.teMaAnimal')} (${this.$t('agent.overceed.totalBall')} : 12)`, val: 12}
        ],
        numberOfBall: 49,
        oddRateAmount: 0,
        bonusRate: 0
      },
      shareholder: {
        originalAmount: 0, // A
        bonusAmount: 0, // B
        parentShareholderRate: 0, // C
        shareholderRate: 0, // D
      },
      bonusAndOddDiff: {
        originalAmount: 0, // A
        parentBonusRate: 0, // B
        bonusRate: 0, // C
        parentOddRate: 0, // D
        oddRate: 0, // E
        parentSharehold: 0, // F
        oddWinLostStatus: 1
      },
      bonusDiff: {
        originalAmount: 0, // A
        parentBonusRate: 0, // B
        bonusRate: 0, // C
        parentSharehold: 0, // D
      },
      oddDiff: {
        originalAmount: 0, // A
        parentOddRate: 0, // B
        oddRate: 0, // C
        status: 1,
        parentSharehold: 0,
      },
      hedge: {
        odd: 0, // A
        amount: 0, // B
        rate: 0, // C
        status: 1, // D
      },
      userProfit:{
        error: [],
        inputFormat: `TeMa|09|100|47|3.2|Win
TeMa|33|200|47|3.2|Lose
3hits3|01,02,03|10|776|12.9|Lose`
      },
      agentProfit:{
        inputFormat: `TeMa|09|100|47|46.5|3.2|3.0|30|Win
TeMa|33|200|47|47|3.2|3.1|80|Lose
3hits3|01,02,03|10|776|770|12.9|12.9|100|Lose`
      },
      result: {
        user: {
          amount: 0,
          profitBeforeBonus: 0,
          bonusAmount: 0,
        },
        userMulti: {
          totalPlayAmount: 0,
          totalBonus: 0,
          totalProfit: 0,

          totalPlayAmountFormalas: [],
          totalBonusFormalas: [],
          totalProfitFormalas: []
        },
        generateBetString: {
          result : "",
          result2: ""
        },
        agent: {
          amountPayToBelow: 0,
          amountGetFromParent: 0,
          amountCommissionBonusRate: 0,
          amountEarn: 0,
        },
        tema: {
          predictAmount: 0,
        },
        overceed: {
          result : 0
        },
        shareholder: {
          playAmount: 0,
          profitAmount: 0,
          playAmountToParent: 0,
          profitAmountToParent: 0,
        },
        bonusAndOddDiff: {
          bonusAmountPayToBelow: 0,
          bonusAmountParentPayToMe: 0,
          bonusCommissionAmount: 0,
          oddAmountPayToBelow: 0,
          oddAmountParentPayToMe: 0,
          oddCommissionAmount: 0,
          totalProfit: 0
        },
        bonusDiff: {
          bonusAmount: 0,
          commissionAmount: 0,
          amountToParent: 0,
        },
        oddDiff: {
          oddAmountPayToBelow: 0,
          oddAmountParentPayToMe: 0,
          oddCommissionAmount: 0,
        },
        hedge: {
          amount: 0,
          profitBeforeBonus: 0,
          bonusAmount: 0,
        },
        userProfit: {
          totalPlayAmount: 0,
          totalBonus: 0,
          totalProfit: 0,

          totalPlayAmountFormalas: [],
          totalBonusFormalas: [],
          totalProfitFormalas: []
        },
        agentProfit: {
          totalPlayAmount: 0,
          totalBonus: 0,
          totalCommissionBonus: 0,
          totalCommissionOdd: 0,
          totalProfit: 0,

          totalPlayAmountFormalas : [],
          totalBonusFormalas : [],
          totalCommissionBonusFormalas : [],
          totalCommissionOddFormalas: [],
          totalProfitFormalas: []
        },
        agentOwnPartProfit : {
          occupiedAmount: 0,
          payBonusToBelow: 0,
          profitAfterBonus: 0,
  
          occupiedAmountFormalas: [],
          payBonusToBelowFormalas: [],
          profitAfterBonusFormalas: []
        }
      },
    };
  },
  components: {
    Calculator,
  },
  mounted() {
    let type = this.$route.query?.type
    this.type = type

    if(type == 'user'){
      this.tabActive = "user"
    }
  },
  computed: {
    
  },
  unmounted() {},
  created() {},
  methods: {
    formattedText(text) {
      let html2 = text.replace(/\|/g, '<span class="pipe">|</span>');
      return html2
    },

    calculate() {
      let profitWin =
        parseFloat(this.user.amount) * parseFloat(this.user.odd) -
        parseFloat(this.user.amount) +
        (parseFloat(this.user.rate) / 100) * parseFloat(this.user.amount);
      let profitLost =
        -parseFloat(this.user.amount) +
        (parseFloat(this.user.rate) / 100) * parseFloat(this.user.amount);
      let profitBeforeBonus = 0;
      let bonusAmount =
        parseFloat(this.user.amount) * (parseFloat(this.user.rate) / 100);

      if (this.user.status == 1) {
        profitBeforeBonus =
          (parseFloat(this.user.amount) * parseFloat(this.user.odd)) - parseFloat(this.user.amount);
      } else {
        profitBeforeBonus = -1 * parseFloat(this.user.amount);
      }

      this.result.user.amount =
        this.user.status == 1 ? profitWin.toFixed(3) : profitLost.toFixed(3);
      this.result.user.profitBeforeBonus = profitBeforeBonus.toFixed(3);
      this.result.user.bonusAmount = bonusAmount.toFixed(3);
    },

    userMultiCalculateResult(){
      let rows = this.userMulti.inputFormat.split("\n");

      let errrorMessage = []

      let totalPlayAmount = 0
      let totalBonus = 0
      let totalProfit = 0

      let formattedRows = [];

      let totalPlayAmountFormalasArr = []
      let totalBonusFormalasArr = []
      let totalProfitFormalasArr = []

      rows.forEach(function(row) {
        let columns = row.split("|");
        formattedRows.push(columns);
        if (columns.length < 6) {
          errrorMessage.push(`${columns} : Invalid input format`)
        } else {
            formattedRows.push(columns);
            
            let playAmount = parseFloat(columns[2])
            let oddRate = parseFloat(columns[3])
            let bonusRate = parseFloat(columns[4])/100
            let winOrLose = columns[5].toLowerCase()

            let totalPlayAmountFormalas = playAmount
            totalPlayAmount += playAmount
            totalPlayAmountFormalasArr.push(`${totalPlayAmountFormalas} = ${totalPlayAmountFormalas}`)

            let totalBonusFormalas = playAmount * bonusRate
            totalBonusFormalasArr.push(`${playAmount} * ${bonusRate} = ${totalBonusFormalas}`)
            totalBonus += totalBonusFormalas

            let totalProfitFormalas = ""

            if(winOrLose == "win" || winOrLose == "赢"){
              totalProfitFormalas = ((playAmount * oddRate) - playAmount) + (bonusRate*playAmount)
              totalProfitFormalasArr.push(`((${playAmount} * ${oddRate}) - ${playAmount}) + (${bonusRate}*${playAmount}) = ${totalProfitFormalas}`)
            }else{
              totalProfitFormalas = (-playAmount) + (bonusRate*playAmount)
              totalProfitFormalasArr.push(`(-${playAmount}) + (${bonusRate}*${playAmount}) = ${totalProfitFormalas}`)
            }

            totalProfit += totalProfitFormalas
        }
      });

      this.result.userMulti = {
        totalPlayAmount: totalPlayAmount.toFixed(3),
        totalBonus: totalBonus.toFixed(3),
        totalProfit: totalProfit.toFixed(3),

        totalPlayAmountFormalas: totalPlayAmountFormalasArr,
        totalBonusFormalas: totalBonusFormalasArr,
        totalProfitFormalas: totalProfitFormalasArr
      }
    },

    generateBetStringCalculation(){
      let refs = this.$refs.generateBetStringResult
      let valStr = this.generateBetString.balls.replace(/\./g, "|");
      var numbers = valStr.match(/\d+(\.\d+)*/g)

      // check is duplicated balls
      let duplicatedArr = this.hasDuplicates(numbers)
      if(duplicatedArr[0]){
        this.$toast(`The duplicated balls ${duplicatedArr} has removed`, "warning")
      }

      // uniqueArray numbers or balls 
      numbers = [...new Set(numbers)];

      let amount = this.generateBetString.amount
      let symbol = this.generateBetString.splitCodeSelected
      let results = []
      numbers.forEach(item => {
        let str = `${item}=${amount}`
        results.push(str)
      })

      this.result.generateBetString.result = results.join(symbol)
      this.result.generateBetString.result2 = `${this.$t("user.generateBetString.result2", {balls: numbers.join(","), totalBall: numbers.length, amount: amount})}`
    },

    agentCalculateResult() {
      let agent = this.agent;
      let amountPayToBelow =
        parseFloat(agent.originalProfit) +
        parseFloat(agent.belowLayerBonusAmount);
      let amountGetFromParent = "";
      if (parseFloat(agent.originalProfit) >= 0) {
        amountGetFromParent =
          parseFloat(agent.originalProfit) *
            (parseFloat(agent.agentShareholdRate) / 100) -
          parseFloat(agent.bonusRate);
      } else {
        amountGetFromParent =
          parseFloat(agent.originalProfit) *
            (parseFloat(agent.agentShareholdRate) / 100) +
          parseFloat(agent.bonusRate);
      }
      let amountCommissionBonusRate =
        parseFloat(agent.bonusRate) - parseFloat(agent.belowLayerBonusAmount);
      let amountEarn =
        parseFloat(agent.originalAmount) *
          (parseFloat(agent.agentShareholdRate) / 100) +
        parseFloat(amountCommissionBonusRate);

      this.result.agent = {
        amountPayToBelow: amountPayToBelow.toFixed(3),
        amountGetFromParent: amountGetFromParent.toFixed(3),
        amountCommissionBonusRate: amountCommissionBonusRate.toFixed(3),
        amountEarn: amountEarn.toFixed(3),
      };
    },

    temaPredictProfitCalculateResult() {
      let tema = this.tema;

      let predictAmount =
        parseFloat(tema.hitBallCollectAmount) *
          parseFloat(tema.oddRateAmountToday) -
        (parseFloat(tema.hitBallCollectAmount) +
          parseFloat(tema.otherBallCollectAmounts)) *
          (1 + parseFloat(tema.bonusRateToday) / 100);

      this.result.tema = {
        predictAmount: predictAmount.toFixed(3),
      };
    },

    overceedCalculateResult() {
      let overceed = this.overceed;
      if(overceed.numberOfBall === 0){
        this.$toast("Drop list is required", "danger")
        return
      }

      let result = 1 * parseFloat(overceed.oddRateAmount) / parseFloat(overceed.numberOfBall) + (parseFloat(overceed.bonusRate)/100)
      this.result.overceed = {
        result: result.toFixed(3),
      };
    },

    shareholderCalculateResult() {
      let shareholder = this.shareholder;

      let playAmount =
        parseFloat(shareholder.originalAmount) *
        (parseFloat(shareholder.shareholderRate) / 100);

      let profitAmount =
        playAmount -
        parseFloat(shareholder.bonusAmount) *
          (parseFloat(shareholder.shareholderRate) / 100);

      let playAmountToParent =
        parseFloat(shareholder.originalAmount) *
        (parseFloat(shareholder.parentShareholderRate) / 100);

      let profitAmountToParent =
        playAmountToParent -
        parseFloat(shareholder.bonusAmount) *
          (parseFloat(shareholder.parentShareholderRate) / 100);

      this.result.shareholder = {
        playAmount: playAmount.toFixed(3),
        profitAmount: profitAmount.toFixed(3),
        playAmountToParent: playAmountToParent.toFixed(3),
        profitAmountToParent: profitAmountToParent.toFixed(3),
      };
    },

    bonusAndOddDiffCalculateResult() {
      let bonusAndOddDiff = this.bonusAndOddDiff;
      let bonusAmountPayToBelow = parseFloat(bonusAndOddDiff.originalAmount) * (parseFloat(bonusAndOddDiff.bonusRate) / 100);
      
      let bonusAmountParentPayToMe =
        parseFloat(bonusAndOddDiff.originalAmount) *
        (parseFloat(bonusAndOddDiff.parentSharehold) / 100) *
        (parseFloat(bonusAndOddDiff.parentBonusRate) / 100);

      // let bonusCommissionAmount = bonusAmountParentPayToMe - bonusAmountPayToBelow

      let bonusCommissionAmount = parseFloat(bonusAndOddDiff.originalAmount) *
      (parseFloat(bonusAndOddDiff.parentSharehold) / 100) *
      ((parseFloat(bonusAndOddDiff.parentBonusRate) / 100) -  (parseFloat(bonusAndOddDiff.bonusRate) / 100));

      let oddAmountPayToBelow =  (parseFloat(bonusAndOddDiff.originalAmount)*parseFloat(bonusAndOddDiff.oddRate)) - parseFloat(bonusAndOddDiff.originalAmount)
      let oddAmountParentPayToMe = ((((parseFloat(bonusAndOddDiff.originalAmount)*(parseFloat(bonusAndOddDiff.parentSharehold)/100))) * parseFloat(bonusAndOddDiff.parentOddRate))) - parseFloat(bonusAndOddDiff.originalAmount)
      // let oddCommissionAmount = oddAmountParentPayToMe - (oddAmountPayToBelow)
      let oddCommissionAmount = (bonusAndOddDiff.originalAmount * (bonusAndOddDiff.parentSharehold/100)) * ((bonusAndOddDiff.parentOddRate) - (bonusAndOddDiff.oddRate))
      
      if(bonusAndOddDiff.oddWinLostStatus == 0){
        oddAmountPayToBelow = 0
        oddAmountParentPayToMe = 0
        oddCommissionAmount = 0
      }

      let totalProfit = bonusCommissionAmount + oddCommissionAmount

      this.result.bonusAndOddDiff = {
        bonusAmountPayToBelow: bonusAmountPayToBelow.toFixed(3),
        bonusAmountParentPayToMe: bonusAmountParentPayToMe.toFixed(3),
        bonusCommissionAmount: bonusCommissionAmount.toFixed(3),
        oddAmountPayToBelow: oddAmountPayToBelow.toFixed(3),
        oddAmountParentPayToMe: oddAmountParentPayToMe.toFixed(3),
        oddCommissionAmount: oddCommissionAmount.toFixed(3),
        totalProfit: totalProfit.toFixed(3)
      };
    },

    bonusDiffCalculateResult() {
      let bonusDiff = this.bonusDiff;
      let bonusAmount =
        parseFloat(bonusDiff.originalAmount) *
        (parseFloat(bonusDiff.bonusRate) / 100);
      
      let amountToParent =
        parseFloat(bonusDiff.originalAmount) *
        (parseFloat(bonusDiff.parentSharehold) / 100) *
        (parseFloat(bonusDiff.parentBonusRate) / 100);

      let commissionAmount = amountToParent - bonusAmount
      this.result.bonusDiff = {
        bonusAmount: bonusAmount.toFixed(3),
        amountToParent: amountToParent.toFixed(3),
        commissionAmount: commissionAmount.toFixed(3),
      };
    },

    oddDiffCalculateResult() {
      let oddDiff = this.oddDiff;

      let oddAmountPayToBelow =  (parseFloat(oddDiff.originalAmount)*parseFloat(oddDiff.oddRate)) - parseFloat(oddDiff.originalAmount)
      let oddAmountParentPayToMe = ((((parseFloat(oddDiff.originalAmount)*(parseFloat(oddDiff.parentSharehold)/100))) * parseFloat(oddDiff.parentOddRate))) - parseFloat(oddDiff.originalAmount)
      let oddCommissionAmount = (oddDiff.originalAmount * (oddDiff.parentSharehold/100)) * ((oddDiff.parentOddRate) - (oddDiff.oddRate))

      if(oddDiff.status == 0){
        oddAmountPayToBelow = 0
        oddAmountParentPayToMe = 0
        oddCommissionAmount = 0
      }

      this.result.oddDiff = {
        oddAmountPayToBelow: oddAmountPayToBelow.toFixed(3),
        oddAmountParentPayToMe: oddAmountParentPayToMe.toFixed(3),
        oddCommissionAmount: oddCommissionAmount.toFixed(3)
      };
    },

    hedgeCalculateResult() {
      let hedge = this.hedge;
      let profitWin =
        parseFloat(hedge.amount) * parseFloat(hedge.odd) -
        parseFloat(hedge.amount) +
        (parseFloat(hedge.rate) / 100) * parseFloat(hedge.amount);
      let profitLost =
        -parseFloat(hedge.amount) +
        (parseFloat(hedge.rate) / 100) * parseFloat(hedge.amount);

      let profitBeforeBonus = 0;
      let bonusAmount =
        parseFloat(hedge.amount) * (parseFloat(hedge.rate) / 100);

      if (hedge.status == 1) {
        profitBeforeBonus = (parseFloat(hedge.amount) * parseFloat(hedge.odd)) - parseFloat(hedge.amount);
      } else {
        profitBeforeBonus = -1 * parseFloat(hedge.amount);
      }

      this.result.hedge = {
        amount:
          hedge.status == 1 ? profitWin.toFixed(3) : profitLost.toFixed(3),
        profitBeforeBonus: profitBeforeBonus.toFixed(3),
        bonusAmount: bonusAmount.toFixed(3),
      };
    },

    userProfitCalculateResult(){
      let rows = this.userProfit.inputFormat.split("\n");

      let errrorMessage = []

      let totalPlayAmount = 0
      let totalBonus = 0
      let totalProfit = 0

      let formattedRows = [];

      let totalPlayAmountFormalasArr = []
      let totalBonusFormalasArr = []
      let totalProfitFormalasArr = []

      rows.forEach(function(row) {
        let columns = row.split("|");
        formattedRows.push(columns);
        if (columns.length < 6) {
          errrorMessage.push(`${columns} : Invalid input format`)
        } else {
            formattedRows.push(columns);
            

            let playAmount = parseFloat(columns[2])
            let oddRate = parseFloat(columns[3])
            let bonusRate = parseFloat(columns[4])/100
            let winOrLose = columns[5].toLowerCase()

            let totalPlayAmountFormalas = playAmount
            totalPlayAmount += playAmount
            totalPlayAmountFormalasArr.push(`${totalPlayAmountFormalas} = ${totalPlayAmountFormalas}`)

            let totalBonusFormalas = playAmount * bonusRate
            totalBonusFormalasArr.push(`${playAmount} * ${bonusRate} = ${totalBonusFormalas}`)
            totalBonus += totalBonusFormalas

            let totalProfitFormalas = ""

            if(winOrLose == "win" || winOrLose == "赢"){
              totalProfitFormalas = ((playAmount * oddRate) - playAmount) + (bonusRate*playAmount)
              totalProfitFormalasArr.push(`((${playAmount} * ${oddRate}) - ${playAmount}) + (${bonusRate}*${playAmount}) = ${totalProfitFormalas}`)
            }else{
              totalProfitFormalas = (-playAmount) + (bonusRate*playAmount)
              totalProfitFormalasArr.push(`(-${playAmount}) + (${bonusRate}*${playAmount}) = ${totalProfitFormalas}`)
            }

            totalProfit += totalProfitFormalas
        }
      });

      // if(errrorMessage[0]){
      //   this.userProfit.error = errrorMessage

      //   console.log("this.userProfit.error ", this.userProfit.error)
      //   return
      // }

      this.result.userProfit = {
        totalPlayAmount: totalPlayAmount.toFixed(3),
        totalBonus: totalBonus.toFixed(3),
        totalProfit: totalProfit.toFixed(3),

        totalPlayAmountFormalas: totalPlayAmountFormalasArr,
        totalBonusFormalas: totalBonusFormalasArr,
        totalProfitFormalas: totalProfitFormalasArr
      }
    },

    agentProfitCalculateResult(){
      let rows = this.agentProfit.inputFormat.split("\n");

      let errrorMessage = ""

      let totalPlayAmount = 0
      let totalBonus = 0
      let totalCommissionBonus = 0
      let totalCommissionOdd = 0
      let totalProfit = 0

      let totalPlayAmountFormalas = []
      let totalBonusFormalasArr = []
      let totalCommissionBonusFormalasArr = []
      let totalCommissionOddFormalasArr = []
      let totalProfitFormalasArr = []

      let formattedRows = [];
      rows.forEach(function(row) {
        let columns = row.split("|");
        formattedRows.push(columns);
        if (columns.length < 6) {
          errrorMessage = `${columns} : Invalid input format`
        } else {
            formattedRows.push(columns);

            let playAmount = parseFloat(columns[2])
            let parentOddRate = parseFloat(columns[3])
            let belowOddRate = parseFloat(columns[4])
            let parentBonusRate = parseFloat(columns[5])/100
            let belowBonusRate = parseFloat(columns[6])/100
            let forwardPercent = parseFloat(columns[7])/100
            let winOrLose = columns[8].toLowerCase()

            // calculate Total Amount
            let playAmountFormalas = playAmount * forwardPercent
            totalPlayAmountFormalas.push(`${playAmount} * ${forwardPercent} = ${playAmountFormalas.toFixed(3)}`)
            totalPlayAmount += playAmountFormalas;

            // calculate totalBonusFormalas
            let totalBonusFormalas = (playAmount * forwardPercent) * parentBonusRate
            totalBonusFormalasArr.push(`(${playAmount} * ${forwardPercent}) * ${parentBonusRate} = ${totalBonusFormalas.toFixed(3)}`)
            totalBonus += totalBonusFormalas

            // calculate totalCommissionBonus
            let totalCommissionBonusFormalas = (playAmount * forwardPercent) * (parentBonusRate - belowBonusRate)
            totalCommissionBonusFormalasArr.push(`(${playAmount} * ${forwardPercent}) * (${parentBonusRate} - ${belowBonusRate}) = ${totalCommissionBonusFormalas.toFixed(3)}`)
            totalCommissionBonus += totalCommissionBonusFormalas

            // calculate totalCommissionOdd
            let totalCommissionOddFormalas = 0
            let totalProfitFormalas = 0

            let forwardAmount = playAmount * forwardPercent

            totalProfitFormalasArr.push(`ForwardAmount : ${playAmount} * ${forwardPercent} = ${forwardAmount}`)
            if(winOrLose == "win" || winOrLose == "赢"){
              totalCommissionOddFormalas = (playAmount * forwardPercent) * (parentOddRate - belowOddRate)
              totalCommissionOddFormalasArr.push(`(${playAmount} * ${forwardPercent}) * (${parentOddRate} - ${belowOddRate}) = ${totalCommissionOddFormalas}`)

              totalProfitFormalas = ((forwardAmount * parentOddRate) - forwardAmount) + (forwardAmount * parentBonusRate)
              totalProfitFormalasArr.push(`((${forwardAmount} * ${parentOddRate}) - ${forwardAmount}) + (${forwardAmount} * ${parentBonusRate}) = ${totalProfitFormalas.toFixed(3)}`)
            }else{
              totalCommissionOddFormalas = 0
              totalCommissionOddFormalasArr.push(`0 = ${totalCommissionOddFormalas}`)

              totalProfitFormalas = -forwardAmount + (forwardAmount * parentBonusRate)
              totalProfitFormalasArr.push(`-${forwardAmount} + (${forwardAmount} * ${parentBonusRate}) = ${totalProfitFormalas.toFixed(3)}`)
            }

            totalCommissionOdd += totalCommissionOddFormalas
            totalProfit += totalProfitFormalas
        }
      });

      this.result.agentProfit = {
        totalPlayAmount: totalPlayAmount.toFixed(3),
        totalBonus: totalBonus.toFixed(3),
        totalCommissionBonus: totalCommissionBonus.toFixed(3),
        totalCommissionOdd: totalCommissionOdd.toFixed(3),
        totalProfit: totalProfit.toFixed(3),

        totalPlayAmountFormalas: totalPlayAmountFormalas,
        totalBonusFormalas: totalBonusFormalasArr,
        totalCommissionBonusFormalas : totalCommissionBonusFormalasArr,
        totalCommissionOddFormalas: totalCommissionOddFormalasArr,
        totalProfitFormalas: totalProfitFormalasArr
      }

      console.log(this.result.agentProfit)
    },

    agentOwnPartProfitCalculateResult(){
      let rows = this.agentOwnPartProfit.inputFormat.split("\n");

      let errrorMessage = []
      let formattedRows = []

      let occupiedAmount = 0
      let payBonusToBelow = 0
      let totalProfitAfterBonus = 0

      let occupiedAmountFormalasArr = []
      let payBonusToBelowFormalasArr = []
      let profitAfterBonusFormalasArr = []

      rows.forEach(function(row) {
        let columns = row.split("|");
        formattedRows.push(columns);
        if (columns.length < 6) {
          errrorMessage.push(`${columns} : Invalid input format`)
        } else {
          formattedRows.push(columns);
          
          let playAmount = parseFloat(columns[2])
          let ownShareHolder = parseFloat(columns[3])/100
          let ownBonusRate = parseFloat(columns[4])/100
          let oddsRate = parseFloat(columns[5])
          let profitAfterBonus = parseFloat(columns[6])

          let occupiedAmountFormalas = playAmount * ownShareHolder
          occupiedAmountFormalasArr.push(`${playAmount} * ${ownShareHolder} = ${occupiedAmountFormalas}`)
          occupiedAmount += occupiedAmountFormalas

          let payBonusToBelowFormalas = playAmount * ownShareHolder * ownBonusRate
          payBonusToBelowFormalasArr.push(`${playAmount} * ${ownShareHolder} * ${ownBonusRate} =${payBonusToBelowFormalas}`)
          payBonusToBelow += payBonusToBelowFormalas

          let profitAfterBonusFormalas = profitAfterBonus * ownShareHolder
          profitAfterBonusFormalasArr.push(`${profitAfterBonus} * ${ownShareHolder} = ${profitAfterBonusFormalas}`)
          totalProfitAfterBonus += profitAfterBonusFormalas
        }
      });

      this.result.agentOwnPartProfit = {
        occupiedAmount: occupiedAmount.toFixed(3),
        payBonusToBelow: payBonusToBelow.toFixed(3),
        profitAfterBonus: totalProfitAfterBonus.toFixed(3),

        occupiedAmountFormalas: occupiedAmountFormalasArr,
        payBonusToBelowFormalas: payBonusToBelowFormalasArr,
        profitAfterBonusFormalas: profitAfterBonusFormalasArr
      }
    },

    clear(type) {
      switch (type) {
        case "user":
          this.user = {
            odd: 0, // A
            amount: 0, // B
            rate: 0, // C
            status: 1, // D
          };

          this.result.user = {
            amount: 0,
            profitBeforeBonus: 0,
            bonusAmount: 0,
          };
          break;
        case "generateBetString":
          this.generateBetString = {
            dropList: [
              { label: `${this.$t('user.generateBetString.space')} ( )`, val: " "},
              { label: `${this.$t('user.generateBetString.comma')} (,)`, val: ","},
              { label: `${this.$t('user.generateBetString.semicolon')} (;)`, val: ";"}
            ],
            balls: "1.2.3.4.5.6.7.8.9.10",
            amount: 0,
            splitCodeSelected: " "
          }

          this.result.generateBetString.result = ""
          this.result.generateBetString.result2 = ""
          break
        case "tema":
          this.tema = {
            hitBallCollectAmount: 0, // A
            otherBallCollectAmounts: 0, // B
            oddRateAmountToday: 0, // C
            bonusRateToday: 0, // D
          };

          this.result.tema = {
            predictAmount: 0,
          };
          break;
        case "overceed":
          this.overceed.numberOfBall = 0
          this.overceed.oddRateAmount = 0
          this.overceed.bonusRate = 0

          this.result.overceed = {
            result: 0
          };
          break;
        case "shareholder":
          this.shareholder = {
            originalAmount: 0, // A
            bonusAmount: 0, // B
            parentShareholderRate: 0, // C
            shareholderRate: 0, // D
          };

          this.result.shareholder = {
            playAmount: 0,
            profitAmount: 0,
            playAmountToParent: 0,
            profitAmountToParent: 0,
          };
          break;
        case "bonusAndOddDiff":
          this.bonusAndOddDiff = {
            originalAmount: 0, // A
            parentBonusRate: 0, // B
            bonusRate: 0, // C
            parentOddRate: 0, // D
            oddRate: 0, // E
            parentSharehold: 0, // F
            oddWinLostStatus: 1
          },

          this.result.bonusAndOddDiff = {
            bonusAmountPayToBelow: 0,
            bonusAmountParentPayToMe: 0,
            bonusCommissionAmount: 0,
            oddAmountPayToBelow: 0,
            oddAmountParentPayToMe: 0,
            oddCommissionAmount: 0,
            totalProfit: 0
          }
          break;
        case "bonusdiff":
          this.bonusDiff = {
            originalAmount: 0, // A
            parentBonusRate: 0, // B
            bonusRate: 0, // C
            parentSharehold: 0, // D
          };

          this.result.bonusDiff = {
            bonusAmount: 0,
            commissionAmount: 0,
            amountToParent: 0,
          };
          break;
        case "odddiff":
          this.oddDiff = {
            originalAmount: 0, // A
            parentOddRate: 0, // B
            oddRate: 0, // C
            status: 1,
            parentSharehold: 0,
          };

          this.result.oddDiff = {
            oddAmountPayToBelow: 0,
            oddAmountParentPayToMe: 0,
            oddCommissionAmount: 0,
          };
          break;
        case "hedge":
          this.hedge = {
            odd: 0, // A
            amount: 0, // B
            rate: 0, // C
            status: 1, // D
          };

          this.result.hedge = {
            amount: 0,
            profitBeforeBonus: 0,
            bonusAmount: 0,
          };
          break;
        case "userProfit":
          this.userProfit={
            error: [],
            inputFormat: `TeMa|09|100|47|3.2|Win
TeMa|33|200|47|3.2|Lose
3hits3|01,02,03|10|776|12.9|Lose`
          },

          this.result.userProfit = {
            totalPlayAmount: 0,
            totalBonus: 0,
            totalProfit: 0,
  
            totalPlayAmountFormalas: [],
            totalBonusFormalas: [],
            totalProfitFormalas: []
          }
          break;
        case "agentProfit":
          this.agentProfit = {
            inputFormat: `TeMa|09|100|47|46.5|3.2|3.0|30|Win
TeMa|33|200|47|47|3.2|3.1|80|Lose
3hits3|01,02,03|10|776|770|12.9|12.9|100|Lose`
          }
          this.result.agentProfit = {
            totalPlayAmount: 0,
            totalBonus: 0,
            totalCommissionBonus: 0,
            totalCommissionOdd: 0,
            totalProfit: 0,
  
            totalPlayAmountFormalas : [],
            totalBonusFormalas : [],
            totalCommissionBonusFormalas : [],
            totalCommissionOddFormalas: [],
            totalProfitFormalas: []
          }
          break;
        case "agentOwnPartProfit":
          this.agentOwnPartProfit = {
            inputFormat: `TeMa|09|100|80|3.2|46.5|2300`
          }

          this.result.agentOwnPartProfit = {
            occupiedAmount: 0,
            payBonusToBelow: 0,
            profitAfterBonus: 0,
    
            occupiedAmountFormalas: [],
            payBonusToBelowFormalas: [],
            profitAfterBonusFormalas: []
          }
          break;
        case "complex":
          this.agent = {
            originalProfit: 0, // A
            originalAmount: 0, // B
            belowLayerBonusAmount: 0, // C
            agentShareholdRate: 0, // D percentage
            parentShareholdRate: 0, // E percentage
            bonusRate: 0, // H
          };

          this.result.agent = {
            amountPayToBelow: 0,
            amountGetFromParent: 0,
            amountCommissionBonusRate: 0,
            amountEarn: 0,
          };
          break;
        default:
          break;
      }
    },

    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$toast("Copied Success", "info", 1000)
      } catch($e) {
        this.$toast("Cannot copy", "danger")
      }
    },

    pasteText() {
      navigator.clipboard.readText().then(pastedText => {
        this.generateBetString.balls = pastedText;
      }).catch(err => {
        console.error('Could not paste text: ', err);
      });
    },

    hasDuplicates(array) {
      let duplicates = {};
      let duplicatedArr = []

      array.forEach((value, index) => {
          if (array.indexOf(value) !== index) {
              if (!duplicates[value]) {
                  duplicates[value] = [index];
              } else {
                  duplicates[value].push(index);
              }
          }
      });

      // Print out duplicated values and their indices
      for (const [key, value] of Object.entries(duplicates)) {
        duplicatedArr.push(key)
      }

      return duplicatedArr
    }
  },
};
