export default {
  appDescription: "它对于计算所有利润非常有帮助，只需输入一些必要的信息即可。",
  calculate: "计算",
  menu: {
    calculate: "基础计算器",
    user: "用户",
    agent: "代理人",
  },
  user: {
    playAmount: "下注金额",
    oddRate: "赔率",
    bonusRate: "退水率",
    profitBeforeBonus: "水前盈亏",
    profitAfterBonus: "实际盈亏",
    bonusAmount: "退水",
    result: "结果",

    subTab: {
      single: "单行记录",
      multi: "多行记录",
      generateBetString: "生成投注串"
    },
    userMulti: {
      userProfit: "用户盈亏",
      inputFormat: "输入格式",
      totalPlayAmount: "总投注额",
      totalBonus: "总退水额",
      totalProfit: "总盈亏额",
      remindText : `输入框支持多行数据，每一行的格式如下 ：项目名 | 球号 | 原始投注金额 | 赔率 | 退水率 | 输或赢`,
    },
    generateBetString: {
      ball: "球",
      amount: "额",
      space: "空格",
      comma: "逗号",
      semicolon: "分号",
      dropList: "输出串分隔字符",
      copy: "复制",
      paste: "粘贴",
      result2 : "{balls} 共{totalBall}个球，每球数量{amount}个。"
    }
  },
  agent: {
    subTab: {
      temaPredictProfit: "特码利润预测",
      onlyShareholder: "纯占成",
      onlyBonusDiff: "纯赚水",
      onlyOddDiff: "纯赚赔",
      onlyHedge: "纯走飞",
      complexCondition: "复合条件",
      bonusOddDiff: "赚水&赚赔",
      userProfit: "用户盈亏",
      agentProfit: "代理转发部分",
      agentOwnPartProfit: "代理商自有部分利润"
    },
    tema: {
      hitBallCollectAmount: "特码球收货金额",
      otherBallCollectAmounts: "其它球收货金额汇总",
      oddRateAmountToday: "当天特码赔率",
      bonusRateToday: "当天特码退水率",

      resultPredict: "预测盈亏",
    },
    overceed: {
      tema: "特码",
      zhengMa: "正码",
      zhengMaTe: "正码特",
      twoSides: "双面",
      colors: "色波",
      teMaAnimal: "特码生肖",
      dropList: "玩法列表",
      bonusRate: "退水率",
      oddRate: "赔率",
      result: "结果",
      overceed: "破盘",
      totalBall: "总球数",
    },
    shareholder: {
      originalProfitAmount: "总盈亏金额",
      originalBonusAmount: "总退水金额",
      parentShareholder: "上缴占成",
      currentShareholder: "本级占成",

      resultOccupiedProfitAmount: "本级占成盈亏金额",
      resultOccupiedBonusAmount: "本级盈亏金额（含退水）",
      resultContributeProfitAmountToParent: "上缴盈亏金额(不含退水）",
      resultContributeRealProfitAmountToParent: "上缴盈亏金额 (含退水）",
    },
    bonusAndOddDiff: {
      bonusAmountPayToBelow: "付给下级退水",
      bonusAmountParentPayToMe: "上级付的退水",
      bonusCommissionAmount: "赚水",
      OddAmountPayToBelow: "上级付的盈亏",
      oddAmountParentPayToMe: "付给下级的盈亏",
      oddCommissionAmountTotalProfit: "赚赔",
      totalProfit: "实际盈亏",
    },
    bonusDiff: {
      originalAmount: "原始投注金额",
      parentBonusRate: "上级退水率",
      currentBonus: "本级退水率",
      parentShareholderRate: "上级占成",

      resultOccuppiedBonusAmount: "本级退水",
      resultOccuppiedCommissionAmount: "本级赚水",
      resultBonusAmountParentIdGiveToMe: "上级退水金额",
      reminderInfo: "(本级赚水：只能赚取本级贡献给上级的金额的退水差率）",
    },
    oddDiff: {
      originalAmount: "原始投注金额",
      parentOddRate: "上级给本级赔率",
      currentOddRate: "本级给下级赔率",
      parentShareholder: "上级占成",

      resultMakeMoneyFromOddDiff: "赠赔金额",
    },
    hedge: {
      hedgeAmount: "走飞金额",
      parentOddRate: "上级赔率",
      bonus: "退水率",
    },
    userProfit: {
      userProfit: "用户盈亏",
      inputFormat: "输入格式",
      totalPlayAmount: "总投注额",
      totalBonus: "总退水额",
      totalProfit: "总盈亏额",
      remindText : `输入框支持多行数据，每一行的格式如下 ：项目名 | 球号 | 原始投注金额 | 赔率 | 退水率 | 输或赢`
    },
    agentProfit: {
      agentProfit: "代理盈亏",
      inputFormat: "输入格式",
      totalForwardAmount: "交付上级金额",
      totalBonusParentPayToMe: "上级付的退水",
      totalCommissionBonus: "赚水",
      totalCommissionOdd: "赠赔",
      totalProfitParentPayToMe: "上级付的盈亏",
      remindText : `输入框支持多行数据，每一行的格式如下 ：项目名 | 球号 | 原始投注金额 | 上级给本级的赔率 | 本级给下级的赔率 | 上级给本级的退水率 | 本级给下级的退水率 | 输或赢`
    },
  },
  calculateResult: "计算结果",
  moreFeatures: "更多功能",
  formulas: "计算过程",
  winlost: "输赢",
  win: "赢",
  lost: "输",
  clear: "清屏",
};
